import type {
  Breadcrumb,
  BreadcrumbHint,
  Event,
  EventHint,
} from "@sentry/react";

export type BeforeSend = (
  event: Event,
  hint: EventHint,
) => PromiseLike<Event | null> | Event | null;

export const composeBeforeSend = (
  ...args: (BeforeSend | null | undefined)[]
): BeforeSend => {
  const fns = args.filter((fn): fn is BeforeSend => typeof fn === "function");

  return async (event, hint) => {
    let result: Event | null = event;
    for (const fn of fns) {
      result = await fn(result!, hint);
      if (!result) return null;
    }

    return result!;
  };
};

export const composeBeforeBreadcrumb = (
  breadcrumb: Breadcrumb,
  hint?: BreadcrumbHint
): Breadcrumb | null => {
  return redactBreadcrumbs(breadcrumb, hint);
};

const hasDataNameValue = (
  attributes: NamedNodeMap,
  targetDataNameValue: string
) => {
  for (const attribute in attributes) {
    if (
      attributes[attribute].name === "data-name" &&
      targetDataNameValue === attributes[attribute].nodeValue
    ) {
      return true;
    }
  }
  return false;
};

const redactBreadcrumbs = (breadcrumb: Breadcrumb, hint?: BreadcrumbHint) => {
  const elemAttributes = hint?.event?.srcElement?.attributes;

  if (hasDataNameValue(elemAttributes, "threadPreview")) {
    const redactedMessage = breadcrumb.message?.replace(
      /\[title="([^"]+)"\]/,
      '[title="<MESSAGE_REDACTED>"]'
    );
    return {
      ...breadcrumb,
      message: redactedMessage,
    };
  }

  return breadcrumb;
};
