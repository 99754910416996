/**
 * Allow a consumer to be notified when ongoing sagas and API calls have finished
 *
 * Internal API:
 * - start(): fn - mark a task as started. returns a function to make the task
 *     as completed
 * - reset(): void - reset the task counter. useful in between tests
 *
 * External API:
 * - waitForAsyncTasks(): Promise - resolves when ongoing sagas and API calls
 *     have finished
 */

import once from "lodash/once";

// nextTick: run a function after event loop clears
// implementation detail: save local setTimeout so stub clocks don't affect us
const _setTimeout = setTimeout;
const nextTick = (fn: () => void) => _setTimeout(fn, 0);

type MarkComplete = () => void;

const resolvedPromise = Promise.resolve();

let currentPromise: Promise<void> | undefined;
let resolve: (() => void) | undefined;
let counter = 0;

function start(): MarkComplete {
  counter += 1;

  return once(function complete() {
    counter -= 1;

    nextTick(() => {
      if (counter === 0 && currentPromise && resolve) {
        resolve();
        currentPromise = resolve = undefined;
      }
    });
  });
}

function waitForAsyncTasks() {
  if (counter === 0) {
    return resolvedPromise;
  }

  if (!currentPromise) {
    currentPromise = new Promise((r) => (resolve = r));
  }
  return currentPromise;
}

function reset() {
  counter = 0;
  currentPromise = resolve = undefined;
}

export { start, waitForAsyncTasks, reset };
