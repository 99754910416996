type MaybeHeader = Record<string, string> | undefined;

export function buildHeaders(defaultHeaders: MaybeHeader, headers: MaybeHeader) {
  const ret = { ...defaultHeaders, ...headers };

  if (Object.keys(ret).length === 0) {
    return undefined;
  }

  return ret;
}
