import { QueryFunction, UseQueryOptions } from "@tanstack/react-query";
import uniqueId from "lodash/uniqueId";
import { useRef, useState } from "react";

export const useQueryScope = <TQueryFnData, TData = TQueryFnData>({
  queryFn,
  onSuccess,
  onError,
  onSettled,
}: {
  queryFn: QueryFunction<TQueryFnData>;
  onSuccess?: UseQueryOptions<TData, Error>["onSuccess"];
  onError?: UseQueryOptions<TData, Error>["onError"];
  onSettled?: UseQueryOptions<TData, Error>["onSettled"];
}): {
  queryFn: typeof queryFn;
  onSuccess: typeof onSuccess;
  onError: typeof onError;
  onSettled: typeof onSettled;
} => {
  const [instanceId] = useState(() => uniqueId());
  const requesterRef = useRef("");

  return {
    queryFn: (...args) => {
      requesterRef.current = instanceId;
      return queryFn(...args);
    },
    onSuccess: (...args) => {
      if (requesterRef.current === instanceId) {
        onSuccess?.(...args);
      }
    },
    onError: (...args) => {
      if (requesterRef.current === instanceId) {
        onError?.(...args);
      }
    },
    onSettled: (...args) => {
      if (requesterRef.current === instanceId) {
        onSettled?.(...args);
      }
    },
  };
};

export const mergeFns = <TFunction extends (...args: unknown[]) => unknown>(
  ...fns: (TFunction | undefined)[]
): TFunction => {
  return ((...args) => {
    fns.forEach((fn) => fn?.(...args));
  }) as TFunction;
};
