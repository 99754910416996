import { QueryKey } from "@tanstack/react-query";
import isEmpty from "lodash/isEmpty";
import negate from "lodash/negate";
import { CUSTOM_HEADERS } from "./constants";

export const makeQueryKey = ({
  fetcherName,
  params,
  variedHeaders,
}: {
  fetcherName: string;
  params?: string | (Record<string, unknown> & { [CUSTOM_HEADERS]?: Record<string, string> });
  variedHeaders?: string[];
}): QueryKey => {
  let cachedParams = params;
  if (params && typeof params !== "string") {
    const { [CUSTOM_HEADERS]: headers, ...restParams } = params;

    cachedParams = restParams;

    if (headers && variedHeaders) {
      cachedParams[CUSTOM_HEADERS] = {};
      for (const cachedHeader of variedHeaders) {
        if (headers[cachedHeader]) {
          cachedParams[CUSTOM_HEADERS][cachedHeader] = headers[cachedHeader];
        }
      }
    }
  }

  return [fetcherName, cachedParams].filter(negate(isEmpty));
};

const queryNames = new Set();

export const registerQuery = (queryName: string): void => {
  if (queryNames.has(queryName)) {
    throw new Error(`There is already a query with name ${queryName}`);
  }

  queryNames.add(queryName);
};
