/**
 * this module holds a singleton superagent for making network requests. it's only
 * useful because integration tests don't run in the browser and need some way
 * to persist a cookie jar across multiple requests (and reset the cookie jar when
 * a test is done)
 * teach/home will be responsible for setting a global instance of superagent so
 * all cookies can be shared
 */

import superagent, { SuperAgentStatic } from "superagent";

/* eslint-disable @typescript-eslint/no-namespace, no-var */
declare global {
  namespace NodeJS {
    interface Global {
      superagent?: SuperAgentStatic & { createdBy?: "test" };
    }
  }
  namespace globalThis {
    var superagent: undefined | (SuperAgentStatic & { createdBy?: "test" });
  }
}
/* eslint-enable @typescript-eslint/no-namespace, no-var */

export function getAgent(): SuperAgentStatic {
  if (Config.nodeEnv === "test" && global.process && global.superagent) {
    // TODO: better backend check
    // use superagent to retain cookies during integration tests
    return global.superagent;
  } else {
    return superagent;
  }
}
