import type { BeforeSend } from "./utils";
import type { Event } from "@sentry/react";

export const ignoreErrors = [
  /iframe adapter timedout/i,
  /request has been terminated/i,
] satisfies (string | RegExp)[];

function isInjectedCode(event: Event | undefined): boolean {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

  if (!frames || frames.length === 0) return false;

  const firstFrame = frames[0];
  if (firstFrame.filename === "<anonymous>") {
    return true;
  }

  // TODO: Maybe remove anything that doesn't have a single stack frame from our codebase?

  return false;
}

export const ignoreWithBeforeSend: BeforeSend = (event, hint) => {
  if (
    isPubnubError(event.exception) ||
    isPubnubError(hint.originalException) ||
    isInjectedCode(event)
  ) {
    return null;
  }

  return event;
};

function isPubnubError(error: unknown) {
  return (
    error &&
    error instanceof Error &&
    "isPubnubError" in error &&
    error.isPubnubError
  );
}
