export const isCypress = typeof window !== "undefined" && "Cypress" in window;
export const isTesting = Config.nodeEnv === "test" || Config.cypressTest || isCypress;

export const isDev = Config.nodeEnv === "development" && !isTesting;
export const isProd = Config.nodeEnv === "production" && !isTesting;

if (!isTesting && !isDev && !isProd) {
  throw new Error("Please set NODE_ENV to either 'testing', 'test', 'development' or 'production'");
}

export const shouldThrowPropTypesErrors = !!((isDev || isTesting) && Config.propTypeErrors);

export const isProdApi = Config.apiEndpoint?.includes("api.classdojo.com");

export { isTesting as isTest, isDev as isDevelopment, isProd as isProduction };

const env = {
  isTest: isTesting,
  isTesting,
  isDev,
  isDevelopment: isDev,
  isProd,
  isProduction: isProd,
  shouldThrowPropTypesErrors,
  isProdApi,
  isCypress,
};

export default env;
