import { Response } from "superagent";

type UndocumentedSuperagentRequest = {
  method: string;
  url: string;
  body: unknown;
};

export function isSuccessStatus(status: number) {
  return status < 300 && status >= 200;
}

// 502-504 are usually related to a server being down, and
// will often work after a retry.  IE9 changes some of these
// codes to 12007 or similar for some reason.
export function isApiDownStatus(status: number) {
  return (status >= 502 && status <= 504) || status > 12000;
}

function parseMessage(text: string) {
  try {
    const body = JSON.parse(text) as any;
    const message = body?.error?.message;
    let detail = body?.error?.detail;
    if (!detail && !message) return text;
    if (detail == null) return message;
    if (typeof detail === "object") detail = JSON.stringify(detail);
    if (detail === "{}") return text;
    return detail;
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch (e) {
    return `MALFORMED ERROR RESPONSE: ${text}`;
  }
}

const getURL = (ctx: { req: UndocumentedSuperagentRequest } | Response) => ("req" in ctx ? ctx?.req?.url : undefined);
const getMethod = (ctx: { req: UndocumentedSuperagentRequest } | Response) =>
  "req" in ctx ? ctx?.req?.method : undefined;
const getBody = (ctx: { req: UndocumentedSuperagentRequest } | Response) => ("req" in ctx ? ctx?.req?.body : undefined);

export class APIResponseError extends Error {
  response: Response;
  request: UndocumentedSuperagentRequest;
  isExpected: boolean;

  constructor(response: Response) {
    const url = getURL(response);
    const method = getMethod(response);
    const body = getBody(response);
    const message = `Error returned in API call: ${response.status} ${method} ${url} – ${parseMessage(response.text)}`;

    // if (!url || !method) throw new Error("Unexpected missing URL or method");

    super(message);

    this.response = response;
    // These as anys are because in many tests we aren't producing a
    // fully valid object with url & methods. Remove conditional throw
    // above and fix tests to get rid of these anys:
    this.request = {
      url: url as any,
      method: method as any,
      body,
    };
    this.isExpected = false;
  }
}

export function isApiResponseError(error: Error | null): error is APIResponseError {
  return !!(
    error &&
    "response" in error &&
    error.response &&
    typeof error.response === "object" &&
    "status" in error.response
  );
}
