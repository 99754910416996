import { useCallback } from "react";
import { useState } from "react";
import { isProduction, isTesting } from "@web-monorepo/environment";
import { InMemoryStorage } from "./InMemoryStorage";

/**
 * A miniature wrapper around session storage so that we can fallback
 * to in-memory storage if session storage is disabled
 */

function getSessionStorage(): Storage {
  try {
    // eslint-disable-next-line @web-monorepo/no-browser-storage
    return window.sessionStorage;
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch {
    /* empty */
  }

  // eslint-disable-next-line no-console
  console.warn("sessionStorage is disabled, using in-memory storage");
  return new InMemoryStorage();
}

const sessionStorage = getSessionStorage();

const SessionStorageWrapper = {
  setItem(key: string, value: string) {
    try {
      sessionStorage.setItem(key, value);
    } catch (e) {
      // eslint-disable-next-line no-console
      if (isProduction || isTesting)
        return console.warn(`unable to set key ${key} to value ${value}`);
      throw e;
    }
  },

  getItem(key: string) {
    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      // eslint-disable-next-line no-console
      if (isProduction || isTesting) console.warn(`unable to get key ${key}`);
      throw e;
    }
  },

  removeItem(key: string) {
    try {
      return sessionStorage.removeItem(key);
    } catch (e) {
      // eslint-disable-next-line no-console
      if (isProduction || isTesting)
        console.warn(`unable to remove key ${key}`);
      throw e;
    }
  },
};

export { SessionStorageWrapper as sessionStorage };

export const useSessionStorage = <T>(key: string, defaultValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = SessionStorageWrapper.getItem(key);
      const itemValue: T =
        item != null || item != void 0 ? (JSON.parse(item) as T) : defaultValue;
      return itemValue;
    } catch (error) {
      if (isProduction) {
        // eslint-disable-next-line no-console
        console.warn(`unable to parse sessionstorage item`);
        return defaultValue;
      }
      throw error;
    }
  });

  const setValue = useCallback(
    (value: T | ((arg: T) => T)) => {
      setStoredValue((oldValue) => {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(oldValue) : value;
        SessionStorageWrapper.setItem(key, JSON.stringify(valueToStore));
        return valueToStore;
      });
    },
    [key]
  );

  return [storedValue, setValue] as const;
};
