import { QueryClient } from "@tanstack/react-query";
import { isTest } from "../utils/env";

export const queryClient = new QueryClient({
  ...(isTest && {
    logger: {
      log: (...args) => {
        // eslint-disable-next-line no-console
        console.log(...args);
      },
      warn: (...args) => {
        // eslint-disable-next-line no-console
        console.warn(...args);
      },
      error: (...args) => {
        if (args.find((a) => a.isExpectedError)) {
          // skip logging expected errors
          return;
        }
        // eslint-disable-next-line no-console
        console.log(...args);
      },
    },
  }),
});
