import { Response } from "superagent";

export const makeApiErrorMessage = ({
  headingMessage,
  response,
  name,
  parsedUrl,
  type,
}: {
  headingMessage: string;
  response: Response;
  name: string;
  parsedUrl: string;
  type: "Member" | "Collection";
}) => {
  let message = [
    headingMessage,
    formatColonList({
      [type]: name,
      Url: parsedUrl,
      "Status code": response.statusCode,
    }),
    "\n",
  ].join("\n");

  if (response.body && response.body.error) {
    // Api sent back an error which contains: details, type and message
    message = `${message}API error details:\n${JSON.stringify(response.body.error)}.`;
  } else {
    message = `${message}API response had no error details on response.body.error.`;
    // If we met this conditions, the most likely thing is that a filtering service is blocking
    // our domain. We add this message so the error is properly treated by our error handlers.
    if (response.statusCode === 307 && response.text?.includes("Request Blocked")) {
      message += ` Empty response from filtering service.`;
    }
  }

  return message;
};

const formatColonList = (items: Record<string | number | symbol, { toString: () => string }>): string => {
  const maxPadding = Math.max(...Object.keys(items).map((k) => k.length)) + 2;

  return Object.entries(items)
    .reduce<string[]>((acc, [label, value]) => {
      acc.push(`${`${label}:`.padEnd(maxPadding, " ")}${value}`);
      return acc;
    }, [])
    .join("\n");
};
