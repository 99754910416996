export function urlPatternFromFetcher<QueryParam extends string | number | symbol>(
  path: string,
  query?: Omit<Record<string, string>, QueryParam>,
  queryParams?: QueryParam[],
): string {
  const pathPart = path.replace(/{/g, ":").replace(/}/g, "");
  const queryPart = new URLSearchParams(query);
  if (queryParams) {
    queryParams.forEach((queryParam) => {
      queryPart.set(String(queryParam), `:${String(queryParam)}`);
    });
  }
  return pathPart + (queryPart?.toString()?.length > 0 ? `?${decodeURIComponent(queryPart.toString())}` : "");
}
